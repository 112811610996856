import Rails from "@rails/ujs";
window.Rails = Rails;
Rails.start();

import * as Turbo from "@hotwired/turbo";

import "channels";

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import ActiveStorageDragAndDrop from "active_storage_drag_and_drop";
ActiveStorageDragAndDrop.start();

import Trix from "trix"
import "@rails/actiontext";
import "@thoughtbot/trix-mentions-element";
window.Trix = Trix;

import LocalTime from "local-time";
LocalTime.start();
window.LocalTime = LocalTime;

// https://alpinejs.dev/upgrade-guide#need-to-call-alpine-start
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

import TomSelect from 'tom-select';
import 'tom-select/dist/css/tom-select.default.css';
window.TomSelect = TomSelect;
document.querySelectorAll('.tom-select').forEach((el) => new TomSelect(el));

// register service workers
import '../src/service-worker.js';

// semantic-ui
import '../semantic-ui/dist/semantic.min.css';
import '../semantic-ui/dist/semantic.min.js';

// styles
import '../src/general.scss';

import 'trix/dist/trix.css';
import '../src/actiontext.scss';
import '../src/active_storage_drag_and_drop.scss';
import '../src/responsive.scss';
import '../src/slideout.scss';

// dark mode should be loaded last so that it can override earlier styles more easily
import '../src/darkmode.scss';

// stimulus
import '../src/stimulus.js';

// initializers and custom components
import '../src/beforeunload.js';
import '../src/click-to-copy.js';
import '../src/headway.js';
import '../src/localtime.js';
import '../src/semantic-ui.js';
import '../src/slideout.js';
import '../src/sortable.js';
import '../src/tablesort.js';
import '../src/webauthn.js';
import '../src/xloadlater.js';

// this should run last
// import '../src/turbolinks.js';

// image assets
const images = require.context('../src/images', true)
const imagePath = (name) => images(name, true)
